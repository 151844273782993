import React from "react";

export default function Books() {
  return (
    <div
      style={{
        marginTop: "20vh",
        width: "100vw",
        height: "100vh",
        textAlign: "center",
        fontSize: "100px",
      }}
    >
      Free Books
    </div>
  );
}
