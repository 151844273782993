import React from "react";

export default function Tour() {
  return (
    <div
      style={{
        marginTop: "20vh",
        width: "100vw",
        height: "200vh",
        textAlign: "center",
        fontSize: "100px",
      }}
    >
      Tour
    </div>
  );
}
